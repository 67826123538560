exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-prismic-cvswapout-content-page-uid-js": () => import("./../../../src/pages/{prismicCvswapoutContentPage.uid}.js" /* webpackChunkName: "component---src-pages-prismic-cvswapout-content-page-uid-js" */),
  "component---src-pages-why-it-matters-js": () => import("./../../../src/pages/why-it-matters.js" /* webpackChunkName: "component---src-pages-why-it-matters-js" */),
  "component---src-pages-why-it-matters-old-index-js": () => import("./../../../src/pages/why-it-matters-old/index.js" /* webpackChunkName: "component---src-pages-why-it-matters-old-index-js" */),
  "component---src-pages-why-it-matters-old-prismic-cvswapout-story-details-uid-js": () => import("./../../../src/pages/why-it-matters-old/{prismicCvswapoutStoryDetails.uid}.js" /* webpackChunkName: "component---src-pages-why-it-matters-old-prismic-cvswapout-story-details-uid-js" */)
}

